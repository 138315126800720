import './style/CollapsableSection.css';
import React, {useState} from 'react';
import { } from '../../redux/localSlice';
import Label from './Label';
import Icon from './Icon';
import DownCaret from '../../media/down-caret@3x.png';

const CollapsableSection = ({ label, content, collapsed = true }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const handleClick = () => {
        // Implement the collapse/expand functionality
        setIsCollapsed(!isCollapsed);
    }
    return (
        <div className="flex vertical flex-1 v-gap-m row-clickable secondary pad-s" onClick={handleClick}>
            <div className="flex horizontal align-center space-between">
                <Label content={label} size="s" bold={true} displayFont={true} secondary={!isCollapsed ? true : false} />
                <Icon icon={DownCaret} size={10} rotate={!isCollapsed ? "180" : "0"} />
            </div>
            {!isCollapsed &&  content}
        </div>
    );
};

export default CollapsableSection;