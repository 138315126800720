import Icon from './Icon';
import './style/Toast.css';
import React from 'react';
import Positive from '../../media/check-circle@3x.png';
import Negative from '../../media/info-circle@3x.png';
import Label from './Label';

const Toast = ({ index, message, type = "system", state = "neutral" }) => {
    return (
        <div className={`keystone-toast flex fit-content align-center h-gap-m ${type === "user" && "user"} ${state}`}>
            {state !== "neutral" && (
                <Icon icon={state === "positive" ? Positive : Negative} size={12} color="white" />
            )}
            <Label content={message} size="s" bold={true} displayFont={true} capitalize={true} color="white" />
        </div>
    );
};

export default Toast;