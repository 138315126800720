import Label from './Label';
import './style/Banner.css';
import React, { useMemo } from 'react';
import { getImgUrl } from '../../util/ImageSource';
import { formatToCurrency, formatPercentWithCommas } from '../../util/Calculate';
import CaretRight from '../../media/caret-right@3x.png';
import ChipRounded from './ChipRounded';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
const Banner = ({ products, handleViewProduct }) => {
    const navigate = useNavigate();
    //const featuredProducts = products?.filter(product => product.marketManufacturer === "KPS" || product.marketManufacturer === "MASTECH");
    const featuredProducts = useMemo(() => products?.filter(product => product.marketMpn === "Flexible Clamp DCM4010FLEX 3000A" || product.marketMpn === "DCM7000BT 600A Clamp Meter"  || product.marketMpn === "MT720 Digital Multimeter"), [products]);
    const randomProducts = useMemo(() => featuredProducts.sort(() => Math.random() - Math.random()).slice(0, 3), [featuredProducts]);
    return (
        <div className="flex vertical relative v-gap-m pad-m no-pad-bot">
            <div className="banner flex vertical relative v-gap-xl pad-l">
                <div className="flex horizontal space-between flex-1 h-gap-xl">
                    <div className="flex vertical">
                        <Label customId="banner-title" content="KPS & MASTECH SALE!" mono={true} color="brand" bold={true} size="xl" />
                        <Label customId="banner-title" content="Save over 60% on all test equipment with a minimum purchase of 20 units." displayFont={true} bold={true} size="s" style={{color: '#9A8DC8'}} />
                    </div>
                    <Button label="Shop All Test Equipment" color="white" brand={true} transparent={true} small={true} onClick={() => navigate('/test-equipment')} textColor='black' />
                </div>
                {/* <img src={KpsLogo} alt="KPS Logo" className="banner-logo" /> */}
                <div className="flex horizontal flex-1 h-gap-xl">
                    {randomProducts.map((product, index) => (
                        <div key={product.id} className={`banner-item flex horizontal flex-1 relative ${index !== 0 ? "mobile-hide" : ""}`}>
                            <img src={getImgUrl(product)} alt={product.marketManufacturer} className={`banner-image ${(product?.marketMpn === "MS2302 Earth Resistance Tester" || product?.marketMpn === "MT720 Digital Multimeter") ? "saturate" : ""}`} />
                            <div className="banner-item-content flex vertical justify-center flex-1 v-gap-xs">
                                <div className="flex vertical v-gap-xxs">
                                    <Label content={product.marketManufacturer} mono={true} bold="true" size="s" style={{color: '#9A8DC8'}} />
                                    <Label customId="banner-text" content={product.marketMpn} mono={true} bold="true" color='white' size="m" shadow={true} />
                                </div>
                                <div className="flex vertical v-gap-xs">
                                    <div className="flex horizontal align-center h-gap-xs">
                                        <ChipRounded label={formatToCurrency(product.price)} positive={true} />
                                        <Button icon={CaretRight} onClick={() => handleViewProduct(product)} iconSize={12} iconColor="black" brand={true} transparent={true} />
                                    </div>
                                    <Label content={`${formatPercentWithCommas((1 - (product.price / product.lowestMarketPrice)))} Off Market Price`} displayFont={true} bold="true" color='white' size="s" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Banner;