import Icon from './Icon';
import Label from './Label';
import './style/Action.css';
import React from 'react';
import CaretRight from '../../media/caret-right@3x.png';

const Action = ({ index, customId = null, reference, onClick, label, negative = false, breadcrumb = false, active = false, onWhite = false, flex = null, color = 'black', hideCaret = false  }) => {
    return (
        <div ref={reference} key={index} id={customId ? customId : null} className={`keystone-action ${breadcrumb ? "flex horizontal align-center h-gap-s" : ""} ${onWhite ? "on-white" : ""} ${flex ? `width-100` : ""} ${negative ? "negative" : ""} ${breadcrumb ? "breadcrumb" : ""} ${active ? "active" : ""}`} onClick={onClick}>
            {(breadcrumb && !hideCaret) && <Icon icon={CaretRight} size={10} />}
            <Label content={decodeURIComponent((label === "kps" || label === "mastech") ? label.toUpperCase() : label)} size={breadcrumb ? "m" : "s"} bold={true} displayFont={true} color={color} capitalize={true} center={flex} />
        </div>
    );
};

export default Action;