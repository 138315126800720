import './style/Modal.css';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const modalTypeConfiguration = {
    "image": {
        allowDismiss: true,
    },
};

const Modal = ({ modalType, content, showModal, setShowModal }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Function to handle keydown event
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            // Dispatch action to close the modal
            dispatch(setShowModal(false));
          }
        };
 
        // Add event listener when the modal is shown if it allows dismissal
        if (showModal && modalTypeConfiguration[modalType]?.allowDismiss) {
          document.addEventListener('keydown', handleKeyDown);
        }
 
        // Clean up the event listener when the component unmounts or modal closes
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showModal, modalType]);

    return showModal && (
        <div className="modal pad-xl" onClick={(e) => setShowModal(false)}>
            {content}
        </div>
    );
};

export default Modal;