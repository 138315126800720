import { createSlice } from '@reduxjs/toolkit';

export const localSlice = createSlice({
    name: 'local',
    initialState: {
        cart: [],
        cookies: "unset",
        scrollPosition: 0,
    },
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        setCookies: (state, action) => {
            state.cookies = action.payload;
        },
        setScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
    },
});

export const { setCart } = localSlice.actions;
export const { setCookies } = localSlice.actions;
export const { setScrollPosition } = localSlice.actions;

export default localSlice.reducer;