import './style/StoreSidebar.css';
import React, { useEffect, useState }  from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Action from '../block/Action';
import Button from '../block/Button';
import Label from '../block/Label';
import Icon from '../block/Icon';
import ShieldIcon from '../../media/shield@3x.png';
import { setScrollPosition } from '../../redux/localSlice';
import { useDispatch } from 'react-redux';

const StoreSidebar = ({ currentCategories, categoryPaths, handleCategoryChange, handleCategoryChangeNew, storeProducts }) => {
    const { pathname, search } = useLocation();
    const [showCategoriesMenu, setShowCategoriesMenu] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isExternal, setIsExternal] = useState(false);

    useEffect(() => {
      const checkIfExternal = () => {
        if (window?.history?.length < 3) {
          setIsExternal(true);
        } else {
          setIsExternal(false);
        }
      };
  
      checkIfExternal();
    }, [pathname]);

    const handleTriggerBackstack = () => {
        if (isExternal || pathname === "/") {
            dispatch(setScrollPosition(0));
            navigate("/");
        } else if (categoryPaths?.length > 0) {
            const possibleProduct = storeProducts?.find((product) => encodeURIComponent(product.marketManufacturer?.toLowerCase()) === categoryPaths[0] && encodeURIComponent(product.marketMpn?.toLowerCase()) === categoryPaths[1]);

            if (!possibleProduct) {
                navigate(-1);
            } else {
                navigate("/");
            }
        }
    };

    const handleOpenAboutUs = () => {
        window.open("https://www.trymaterialize.com/", "_blank");
    };

    const handleOpenTermsOfUse = () => {
        window.open("https://www.trymaterialize.com/terms-of-use", "_blank");
    };

    const handleOpenPrivacyPolicy = () => {
        window.open("https://www.trymaterialize.com/privacy-policy", "_blank");
    };
    
    return (
        <div id="store-sidebar" className="flex vertical v-gap-m pad-m no-pad-right relative">
            <div id="categories-wrapper" className={`flex vertical reverse-column pad-s v-gap-s keystone-card ${showCategoriesMenu ? "menu-visible" : ""}`}>
                <Action label={(pathname === "/" || isExternal) ? "All Products" : "Back"} customId="category-navigate" onClick={handleTriggerBackstack} disabled={pathname === "/"} />
                {currentCategories?.map((category) => (
                    category?.children?.length > 0 && (
                        <Action
                            key={category.name}
                            label={`${(category.name.replaceAll("-", " "))}`}
                            onClick={() => handleCategoryChange(category)} />
                    )
                ))}
            </div>
            <div id="category-select" className="flex vertical pad-s v-gap-s">
                <Action label={!showCategoriesMenu ? "Select Category" : "Hide Categories"} color="white" onClick={() => setShowCategoriesMenu(!showCategoriesMenu)} />
            </div>
            <div id="verified-mfg" className="flex horizontal pad-l v-gap-xxs keystone-card align-center">
                <div className="flex vertical v-gap-xs">
                    <Label content="Authenticity Guarantee" displayFont={true} bold={true} size="m" />
                    <Label content="All stock is certified excess and ships directly from a verified manufacturer." displayFont={true} bold={true} size="s" secondary={true} />
                </div>
                <img src={ShieldIcon} style={{width: "32px", height: "32px"}} /> 
            </div>
            <div id="footer-links" className="flex vertical flex-1 v-gap-l space-between">
                <div id="footer-links" className="flex vertical pad-l flex-1 v-gap-l">
                    <Label content="About Us" secondary={true} displayFont={true} bold={true} size="s" onClick={handleOpenAboutUs} />
                    <Label content="Terms of Use" secondary={true} displayFont={true} bold={true} size="s" onClick={handleOpenTermsOfUse} />
                    <Label content="Privacy Policy" secondary={true} displayFont={true} bold={true} size="s" onClick={handleOpenPrivacyPolicy} />
                    <a href="mailto:orders@trymaterialize.com" className="secondary size-s bold no-decoration" target="_blank" rel="noreferrer">orders@trymaterialize.com</a>
                </div>
            </div>
        </div>
    );
};

export default StoreSidebar;