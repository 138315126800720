import Label from './Label';
import './style/Tile.css';
import React from 'react';

const Tile = ({ index, onClick = null, content, rowStyle = false }) => {
    return (
        <div key={index} className={`tile ${rowStyle ? "row-style" : ""} ${onClick ? "clickable" : ""}`} onClick={onClick}>
            {content || <Label content="Make sure to set the content property on the Tile component" />}
        </div>
    );
};

export default Tile;