import './style/StoreContent.css';
import React,  { useState, useEffect } from 'react';
import Label from '../block/Label';
import { formatToCurrency, formatNumberWithCommas } from '../../util/Calculate';
import { getImgUrl } from '../../util/ImageSource';
import { useDispatch } from 'react-redux';
import Button from '../block/Button';
import Divider from '../block/Divider';
import Input from '../block/Input';
import ReCAPTCHA from "react-google-recaptcha";
import { submitOffer } from '../../redux/temporarySlice';
import { setCart } from '../../redux/localSlice';
import SimpleLoader from '../block/SimpleLoader';
import { useSelector } from 'react-redux';
import TrashIcon from '../../media/trash@3x.png';
import CartDisplayIcon from '../../media/cart-display@3x.png';
import availablePaths from './3d-image-paths.json';
import Icon from '../block/Icon';
import Image from '../block/Image';
import Tile from '../block/Tile';

const Cart = (
    { 
        items,
        handleViewProduct,
        setShowSuccessToast
    }
) => {
    const imagePaths = availablePaths;
    const sitekey = window.location?.href?.includes('localhost') ? '6LeRb4AqAAAAAMi0AgNFSMFxyw9bo5q2wePZ9p3u' : '6LeVWn8qAAAAAHHCHAzhrmTQjuadBu9fzePxRzyo'
    const dispatch = useDispatch();
    const temporary = useSelector((state) => state.temporary);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [awaitingSubmittedOffer, setAwaitingSubmittedOffer] = useState(false);
    const [errorSubmittingOffer, setErrorSubmittingOffer] = useState(null);

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaComplete(true);
            setCaptchaResponse(value);
        }
    };

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    const validateFields = () => {
        let valid = true;

        if (name?.length === 0 || email?.length === 0 || addressLine1?.length === 0 || city?.length === 0 || zip?.length === 0 || state?.length === 0 || country?.length === 0 || !captchaComplete) {
            valid = false;
        }

        if (!validateEmail(email)) {
            valid = false;
        }

        setSubmitEnabled(valid);
    };

    useEffect(() => {
        validateFields();
    }, [email, name, addressLine1, addressLine2, city, zip, state, country, captchaComplete]);

    useEffect(() => {
        if (temporary?.submittingOffer) {
            setAwaitingSubmittedOffer(true);
        }
    }, [temporary?.submittingOffer]);

    useEffect(() => {
        if (temporary?.errorSubmittingOffer !== null && awaitingSubmittedOffer) {
            setErrorSubmittingOffer(temporary.errorSubmittingOffer);
        } else if (temporary?.submittedOffers?.length > 0 && awaitingSubmittedOffer) {
            setEmail("");
            setName("");
            setAddressLine1("");
            setAddressLine2("");
            setCity("");
            setZip("");
            setState("");
            setCountry("");
            setCaptchaResponse("");
            setErrorSubmittingOffer(null);
            setShowSuccessToast(true);
            dispatch(setCart([]));
        }
        setAwaitingSubmittedOffer(false);
    }, [temporary?.errorSubmittingOffer, temporary?.submittedOffers]);

    const handleOrderRequest = () => {
        if (!submitEnabled) {
            return;
        }

        let offer = {
            recaptcha_response: captchaResponse,
            line_items: items.map((cartItem) => {
                return {
                    mpn: cartItem.product.marketMpn,
                    manufacturer: cartItem.product.marketManufacturer,
                    quantity: cartItem.quantity,
                    unit_price: cartItem.product.price,
                    currency: "USD"
                };
            }),
            buyer: {
                email: email,
                shipping_address: {
                    line1: addressLine1,
                    line2: addressLine2,
                    city: city,
                    region: state,
                    zip: zip,
                    country: country
                }
            }
        }

        dispatch(submitOffer({ offer }));
    };

    const handleDeleteCartItem = (e, cartItem) => {
        e.stopPropagation();
        dispatch(setCart(items.filter((item) => item.product.marketManufacturer !== cartItem.product.marketManufacturer && item.product.marketMpn !== cartItem.product.marketMpn)));
    };

    return (
        <div className="flex vertical v-gap-l mobile-footer-pad">
            <div className="flex vertical keystone-card overflow-hidden">
                {items?.map((cartItem, index) => (
                    <Tile
                        key={index + cartItem.product.marketMpn}
                        index={index + cartItem.product.marketMpn}
                        onClick={() => handleViewProduct(cartItem.product)}
                        rowStyle={true}
                        content={
                            <div className="flex horizontal height-100 h-gap-m pad-xs align-center">
                                <Image source={getImgUrl(cartItem.product)} altTag={cartItem.product.marketMpn} width="medium" />
                                <div className="flex horizontal flex-1 space-between h-gap-m">
                                    <div className="flex vertical flex-2">
                                        <Label content={cartItem.product.marketManufacturer || ""} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                        <Label content={cartItem.product.marketMpn} size="m" mono={true} />
                                    </div>
                                    <div className="flex vertical flex-1">
                                        <Label content={`${formatNumberWithCommas(cartItem.quantity)} x ${formatToCurrency(cartItem.product.price)}`} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                        <Label content={formatToCurrency(cartItem.product.price * cartItem.quantity)} size="m" mono={true} />
                                    </div>
                                </div>
                                <Button icon={TrashIcon} onClick={(e) => handleDeleteCartItem(e, cartItem)} />
                            </div>
                        }
                    />
                ))}
                {items?.length === 0 && (
                    <div className="flex vertical align-center pad-xl v-gap-s">
                        <Icon icon={CartDisplayIcon} size={48} color="#6f7aa5" />
                        <Label content="Your cart is empty" size="m" bold={true} displayFont={true} center={true} secondary={true} />
                    </div>
                )}
            </div>
            {items?.length > 0 && (
                <div className="flex horizontal relative">
                    <div className="flex horizontal flex-1 mobile-hide"></div>
                    <div id="order-request" className="flex vertical keystone-card-secondary">
                        <div className="flex horizontal h-gap-xl pad-m space-between white-bg">
                            <Label content="Order Request" size="m" bold={true} displayFont={true}/>
                        </div>
                        <Divider vertical={false} />
                        <div className="flex vertical pad-m no-pad-bot v-gap-s">
                            <Label content="Contact Information" size="s" bold={true} displayFont={true}/>
                            <div className="flex vertical v-gap-xs">
                                <Input placeholder="Name" onWhite={false} flex={true} onChange={(e) => setName(e.target.value)} />
                                <Input placeholder="Email" type="email" onWhite={false} flex={true} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex vertical pad-m v-gap-s">
                            <Label content="Shipping Information" size="s" bold={true} displayFont={true}/>
                            <div className="flex vertical v-gap-xs">
                                <Input placeholder="Address line 1" onWhite={false} flex={true} onChange={(e) => setAddressLine1(e.target.value)} />
                                <Input placeholder="Address line 2" onWhite={false} flex={true} onChange={(e) => setAddressLine2(e.target.value)} />
                                <div className="flex horizontal flex-1 width-100 h-gap-xs">
                                    <Input placeholder="City" onWhite={false} flex={true} onChange={(e) => setCity(e.target.value)} />
                                    <Input placeholder="Zip" onWhite={false} flex={true} onChange={(e) => setZip(e.target.value)} />
                                </div>
                                <div className="flex horizontal flex-1 width-100 h-gap-xs">
                                    <div className="flex horizontal flex-1 h-gap-xs">
                                        <Input placeholder="State" onWhite={false} flex={true} onChange={(e) => setState(e.target.value)} />
                                    </div>
                                    <div className="flex horizontal flex-1 h-gap-xs">
                                        <Input placeholder="Country" onWhite={false} flex={true} onChange={(e) => setCountry(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="flex vertical pad-m v-gap-s no-pad-top">
                            <ReCAPTCHA
                                sitekey={sitekey} 
                                onChange={handleCaptchaChange} />
                        </div>
                        <Divider vertical={false} />
                        <div className="flex vertical space-between pad-m v-gap-m white-bg">
                            <div className="flex horizontal h-gap-s space-between">
                                <div className="flex vertical">
                                    <Label content="Total:" bold={true} size="m" displayFont={true} />
                                    <Label content="No payment is required at this time." size="s" bold={true} displayFont={true} secondary={true} />
                                </div>
                                <Label content={formatToCurrency(items.reduce((acc, cartItem) => acc + (cartItem.product.price * cartItem.quantity), 0))} size="m" mono={true} />
                            </div>
                            <Button label="Submit Order Request" brand={true} flex={true} large={true} disabled={!submitEnabled} onClick={handleOrderRequest} />
                            <div className="flex vertical">
                                <Label content="Shipping quote and purchase order will be sent upon confirmation from the storing manufacturer." size="s" bold={true} displayFont={true} secondary={true} />
                            </div>
                        </div>
                        <SimpleLoader loading={temporary.submittingOffer} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;